import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ICrewQueryParam,
  IthunkAPI,
  ICrewPostResponse,
  iCrew,
  ICrewDeleteResponse,
} from "../../types";
import { BearerToken } from "../../services/auth";
import { Auth } from "aws-amplify";
import { clearCrewDeleteData, clearCrewPostData } from "../slices/crewSlice";

export const fetchCrewList = createAsyncThunk<
  iCrew[],
  Partial<ICrewQueryParam>,
  IthunkAPI
>("crew/fetchCrewList", async (params, thunkAPI) => {
  const { dispatch, rejectWithValue } = thunkAPI;
  //   const token = await dispatch(getToken());
  const token = BearerToken();
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/crews`, {
      params: params,
      headers: {
        //   Authorization: `Bearer ${token}`,
        Authorization: token,
      },
    });
    return response.data;
  } catch (err: any) {
    if (axios.isCancel(err)) {
      // Handle cancellation separately
      return rejectWithValue({ canceled: true });
    } else {
      // Handle other errors
      return rejectWithValue(err?.response?.data?.errorDescription);
    }
  }
});

export const postCrew = createAsyncThunk<ICrewPostResponse, [iCrew], IthunkAPI>(
  "crew/postCrew",
  async (payload, thunkAPI) => {
    //Clear previous states

    const { dispatch, rejectWithValue } = thunkAPI;
    dispatch(clearCrewPostData());

    const url = `${process.env.REACT_APP_API_URL}/crews`;

    try {
      // const token = await dispatch(getToken());
      const token = BearerToken();
      const response = await axios.post(url, payload, {
        headers: {
          // Authorization: `Bearer ${token}`
          Authorization: token,
        },
      });
      return response.data[0];
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.errorDescription);
    }
  }
);

export const deleteCrew = createAsyncThunk<
  ICrewDeleteResponse,
  string,
  IthunkAPI
>("crew/deleteCrew", async (payload, thunkAPI) => {
  //Clear previous states

  const { dispatch, rejectWithValue } = thunkAPI;
  dispatch(clearCrewDeleteData());

  const url = `${process.env.REACT_APP_API_URL}/crews/${payload}/delete`;

  try {
    // const token = await dispatch(getToken());
    const token = BearerToken();
    const response = await axios.post(url, payload, {
      headers: {
        // Authorization: `Bearer ${token}`
        Authorization: token,
      },
    });
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error?.response?.data?.errorDescription);
  }
});
