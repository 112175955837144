import { Auth } from "aws-amplify";
import {
  GetCookie,
  SetCookie,
  SetUserDataCookie,
  isAuthenticated,
  GetError,
} from "../utils/helper";
import { COOKIE_NAME, LOGIN_URL } from "./config";

/**
 * Redirects to Login Page if isAuthenticated returns false
 */
export const LoginHandler = () => {
  Auth.currentSession()
    .then((data) => {
      if (data) {
        const token = data?.getIdToken()?.getJwtToken();
        // const token = 'eyJraWQiOiJ2ZEVYRG1MenBRaEtkcEU1RFJQMnQ4ZW53a2VsK2tvZXllRXpWcW1GckgwPSIsImFsZyI6IlJTMjU2In0.eyJjdXN0b206ZW1wbG95ZWVJZCI6IjRkZjQxMzBkLWE3OGUtNGJkNS04ZjAxLTNkYmFhOWNiNTM5NSIsInN1YiI6ImY2MWJkN2QyLTFiM2ItNDg4MS05MGJmLTJkNmZjNmI1MTM5NiIsImN1c3RvbTpzdWJzY3JpYmVyTmFtZSI6IlRFU1QgU1VCU0NSSUJFUiBDT01QQU5ZIDEgKERPIE5PVCBERUxFVEUpIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy13ZXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtd2VzdC0xX3lsTXdHd0NlOSIsImN1c3RvbTpzdWJzY3JpYmVyVHlwZSI6IkNPTVBBTlkiLCJwaG9uZV9udW1iZXJfdmVyaWZpZWQiOnRydWUsImNvZ25pdG86dXNlcm5hbWUiOiJmNjFiZDdkMi0xYjNiLTQ4ODEtOTBiZi0yZDZmYzZiNTEzOTYiLCJjdXN0b206c3Vic2NyaWJlcklkIjoiYzNjMjRmYTEtMWQ2Zi00MWRiLTllMzAtNmNiYjFjNzUzN2E0Iiwib3JpZ2luX2p0aSI6IjA4M2M5YjExLTdjOGMtNDk2OC1iNzk4LTk5ZDg2MDQ0YTNjMiIsImF1ZCI6IjZ0bzE2cmxvYWFhZnRscWMzdHRlM2RybHZsIiwiZXZlbnRfaWQiOiI3MzVkOTBkYS04NjdlLTRlNTQtODRhYi1mMWNkMmExZDM0MDkiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY4MjQ5NDc4OSwibmFtZSI6IlRFU1QgRVhFQ1VUSVZFIDEgKERPIE5PVCBERUxFVEUpIiwicGhvbmVfbnVtYmVyIjoiKzE2NjY2NjY2NjYxIiwiY3VzdG9tOmVtcGxveWVlR3JvdXBJZCI6IjVjZTY1OTFjLWY0ZDMtNGI1Mi05MDAyLWQ4M2NiYWJhZjE0NiIsImV4cCI6MTY4MjU4MTE4OSwiY3VzdG9tOnJvbGUiOiJFWEVDVVRJVkUiLCJpYXQiOjE2ODI0OTQ3ODksImp0aSI6ImFhMDg4YjljLWYwMzEtNDk0OC04ZWNhLThkOTcxNGQ2ZmE1MCIsImVtYWlsIjoic2Etbm90aWZpY2F0aW9uLWRlditleGVjdXRpdmVAd2FrZW5jb2RlLmNvbSJ9.C7AZhi1SvSMUVE1BJYHzee8IHFl72Jqzn6RuOkA6jnRT23qIuW3h7ZsADuZoJsmIQL1uj-gHSMkkN8oNmlfkShBCFgPLWtWe2tYwQIEL9Kzz7IC2k0qG_O0_wFFXaloHhYPsslCh5RQBHPukuqwSAKs0ecmIFQMS64_kr5PHFiZHm1yFfqSTfmrOeIf30UUnIHJbF66vYtm0akzdmT6BPTcbeOgIXhXaVAaYZuZh7dvry9PVu5twNILkT7j4--tkNeVq_AIQMw8Vt5SUUZ6IS9hbNk7HiHrBrJcJIrPGDSPFA8OcphO7RHGtE2gMzuDKya-iWeK_DjWogDedqfPbsg';
        SetCookie(COOKIE_NAME.accessToken, token, 0.5);
        SetUserDataCookie(isAuthenticated(token));
        window.location.href = "";
      } else {
        console.error("Log in response Empty !");
      }
    })
    .catch((e) => {
      GetError(e);
      window.location.href = LOGIN_URL || "";
    });
};

/**
 * Returns string used for Authorization header
 */
export const BearerToken = () =>
  `Bearer ${GetCookie({ cName: COOKIE_NAME.accessToken })}`;
