export const BASE_URL = process.env.REACT_APP_API_URL;
// export const BASE_URL = process.env.REACT_APP_DEV_API_URL;

export const LOGIN_URL = process.env.REACT_APP_AUTH_URL;

export const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN;

export const MSA_APP_URL = process.env.REACT_APP_MSA_URL;

export const MAPS_URL = process.env.REACT_APP_MAPS_URL;

// Used to Access Cookie by Name
export const COOKIE_NAME = {
  accessToken: `${process.env.REACT_APP_COOKIE_PREFIX}access_token`,
  accountId: `${process.env.REACT_APP_COOKIE_PREFIX}user_id`,
  accountName: `${process.env.REACT_APP_COOKIE_PREFIX}user_name`,
  userEmail: `${process.env.REACT_APP_COOKIE_PREFIX}user_email`,
  userPhone: `${process.env.REACT_APP_COOKIE_PREFIX}user_phone`,
  expiresAt: `${process.env.REACT_APP_COOKIE_PREFIX}expires_at`,
};

// Amplify configuration
export const AWS_CONFIG = {
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USERPOOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USERPOOL_WEBCLIENT_ID,
    mandatorySignIn: true,
    authenticationFlowType: 'CUSTOM_AUTH',
    cookieStorage: {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      path: '/',
      expires: 365,
      secure: true,
    },
  },
  Analytics: {
    disabled: true,
  },
};

/*
User Object data
    {
      sub: '15650a95-b2e2-4d90-9e36-18db890d487f',
      email_verified: true,
      'custom:salesforceId': '0051M000008vygiQAA',
      iss: 'https://cognito-idp.us-east-2.amazonaws.com/us-east-2_mLCP7pPmG',
      'custom:subscriberType': 'Company',
      'custom:id': '0011M00002QiDFTQA3',
      'cognito:accountName': '15650a95-b2e2-4d90-9e36-18db890d487f',
      aud: '46f7aprv7suoj9lps33anpilpl',
      event_id: '088997b1-40cb-497d-9f09-ad17084eb0bc',
      token_use: 'id',
      'custom:userProfile': 'Service Activator System Admin',
      auth_time: 1635808613,
      name: 'Test Contact SA',
      phone_number: '+16666666661',
      exp: 1635851814,
      'custom:salesforceContactId': '0031M000034jf3oQAA',
      iat: 1635808614,
      email: 'sa-notification-dev+systemadmin@wakencode.com',
      'custom:accountName': 'Test Master Account',
    }
  */
