import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom";
import { Amplify, Auth } from "aws-amplify";
import { AWS_CONFIG } from "./services/config";
import App from "./App";

Sentry.init({
  // dsn: process.env.SENTRY_DSN,
  dsn: "https://ae6703d1f3f7a447c076b4bb3b437195@o4505957389238272.ingest.us.sentry.io/4507452172599296",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: [
  //   "localhost",
  //   /^https:\/\/api\.myserviceactivator\.com/,
  // ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Amplify configuration
Amplify.configure(AWS_CONFIG);
Auth.configure();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
