import { endOfMonth, startOfMonth, eachDayOfInterval } from "date-fns";
import addDays from "date-fns/addDays";
import eachWeekOfInterval from "date-fns/eachWeekOfInterval";
import moment from "moment";
import { ReactComponent as CancelIcon } from "../assets/icons/cancel_circled.svg";
import { ReactComponent as LinkIcon } from "../assets/icons/open_link.svg";
import { ReactComponent as PencilIcon } from "../assets/icons/pencil.svg";
import React from "react";

export const CONSTANTS = {
  ADD_TECHNICIAN: "Add Technician",
  APPLY: "Apply",
  APPLY_FILTER: "Apply Filter",
  CANCEL: "Cancel",
  CANCELLED: "Cancelled",
  CREATE_CREW: "Create Crew",
  COMPLETED: "COMPLETED",
  DAILY: "Daily",
  DISCARD_CHANGES: "Discard Changes",
  DISCARD_N_CONTINUE: "Discard & Continue",
  DONE: "Done",
  EDIT_DETAILS: "Edit details",
  EDIT_WORK_ORDER_DETAILS: "Edit Work Order Details",
  EDIT_MASS_WORK_ORDER_DETAILS: "Mass Update of Workorders",
  APPLY_TO_ALL_FUTURE_WORK_ORDERS: "Update Future assignment",
  MASS_APPLY_TO_ALL_FUTURE_WORK_ORDERS:
    "Update Future assignment for all Workorders",
  MASS_APPLY_TO_ALL_FUTURE_WORK_ORDERS_INFO_MESSAGE:
    "When on Technician view, crew assingment will not be updated and when on Crew view, technician assignment will not be updated.",
  APPLY_TO_ALL_FUTURE_WORK_ORDERS_INFO_MESSAGE:
    "This will apply the assignment changes to all future work orders also along with this workorder. If you unassign a technician/crew, it will be unassigned from all future work orders. This will not update Estimated Time Required and Work Order Date.",
  ESTIMATED_TIME_REQUIRED: "Estimated Time Required",
  ESTIMATED_SERVICE_TIME: "Estimated service time",
  FILTER_TECHNICIAN: "Filter Technician",
  IN_PROGRESS: "IN PROGRESS",
  INCOMPLETE: "INCOMPLETE",
  MONTHLY: "Monthly",
  PENDING: "PENDING",
  PROCEED: "Proceed",
  REMOVE_WORK_ORDER: "Remove Work order",
  SELECT: "Select",
  SELECT_TECHNICIAN: "Select Technician",
  SELECT_SERVICES: "Select Service",
  TOTAL_WORK_HOURS: 8,
  UNASSIGN: "Unassign",
  UNASSIGN_ALL_WO: "Unassign all work orders",
  MASS_UPDATE_WO: "Reassign all work orders",
  VIEW_JOB: "View Job",
  VIEW_WORK_ORDER: "View work order",
  WEEKLY: "Weekly",
  WORK_ORDER: "Work Order",
  WO_DETAILS: "Work Order Details",
  WELCOME_TO_SCHEDULER: "Welcome to the serviceactivator Scheduler.",
  WO_DATE: "WorkOrder Date",
  TECHNICIAN: "TECHNICIAN",
  SERVICE: "Service",
  TECHNICIAN1: "Technician 1",
  TECHNICIAN2: "Technician 2",
  TECHNICIAN3: "Technician 3",
  TECHNICIAN4: "Technician 4",
  COLLAPSE_ALL: "Collapse All",
  // UNASSIGNED_WORK_ORDERS: "Unassigned Work Orders",
  COMPLETED_WORK_ORDERS: "Completed Work Orders",
  JOB: "Job",
  EDIT_CREW: "Edit Crew",
  DELETE_CREW: "Delete Crew",
};

export const WeekInterval = eachWeekOfInterval(
  {
    start: new Date(),
    end: addDays(new Date(), 30),
  },
  { weekStartsOn: 1 } // start on monday (overriding default 0=sunday)
);

export const FirstWeekFirstDay = WeekInterval[0];
export const FirstWeekLastDay = new Date(addDays(FirstWeekFirstDay, 6));
export const LastWeekLastDay = new Date(addDays(WeekInterval.slice(-1)[0], 6));
export const FirstDayOfMonth = (date: Date) => startOfMonth(date || new Date());
export const LastDayOfMonth = (date: Date) => endOfMonth(date || new Date());

export const MonthDays = (date: Date) =>
  eachDayOfInterval({
    start: FirstDayOfMonth(date),
    end: LastDayOfMonth(date),
  });

// check if param date is in the same month as today
const urlParams = new URLSearchParams(window.location.search);
const crewStartDateText = urlParams.get("crewStartDate");
const crewStartDate = new Date(
  moment(crewStartDateText, "YYYYMMDD").format("MM/DD/YYYY")
);

export const WO_FILTER_TIMEPERIOD = [
  {
    id: CONSTANTS.DAILY,
    name: CONSTANTS.DAILY,
    // start: null,
    start: crewStartDateText ? crewStartDate : new Date(),
    end: crewStartDateText
      ? new Date(crewStartDate.getTime() + 24 * 60 * 60 * 1000)
      : new Date(),
  },
  {
    id: CONSTANTS.WEEKLY,
    name: CONSTANTS.WEEKLY,
    start: FirstWeekFirstDay,
    end: FirstWeekLastDay,
    // disabled: true,
  },
  {
    id: CONSTANTS.MONTHLY,
    name: CONSTANTS.MONTHLY,
    start: FirstDayOfMonth(new Date()),
    end: LastDayOfMonth(new Date()),
  },
];

export const OPTIONS_ASSIGNED_TASK_MENU = [
  {
    id: 1,
    name: CONSTANTS.EDIT_DETAILS,
    icon: React.createElement(PencilIcon),
  },
  { id: 2, name: CONSTANTS.VIEW_JOB, icon: React.createElement(LinkIcon) },
  {
    id: 3,
    name: CONSTANTS.VIEW_WORK_ORDER,
    icon: React.createElement(LinkIcon),
  },
  {
    id: 4,
    name: CONSTANTS.UNASSIGN,
    className: "option-unassign",
    icon: React.createElement(CancelIcon),
  },
];

export const OPTIONS_UNASSIGNED_TASK_MENU = [
  { id: 2, name: CONSTANTS.VIEW_JOB, icon: React.createElement(LinkIcon) },
  {
    id: 3,
    name: CONSTANTS.VIEW_WORK_ORDER,
    icon: React.createElement(LinkIcon),
  },
];

export const OPTIONS_TECHNICIAN_MENU = [
  // {
  //   id: CONSTANTS.UNASSIGN_ALL_WO,
  //   name: CONSTANTS.UNASSIGN_ALL_WO,
  //   className: "option-unassign",
  //   icon: React.createElement(CancelIcon),
  // },
  {
    id: CONSTANTS.MASS_UPDATE_WO,
    name: CONSTANTS.MASS_UPDATE_WO,
    icon: React.createElement(PencilIcon),
  },
];

export const OPTIONS_TECHNICIAN_FILTER = [
  CONSTANTS.JOB,
  CONSTANTS.SERVICE,
  CONSTANTS.TECHNICIAN,
];

export const OPTIONS_CREW_MENU = [
  {
    id: CONSTANTS.EDIT_CREW,
    name: CONSTANTS.EDIT_CREW,
    icon: React.createElement(PencilIcon),
  },
  {
    id: CONSTANTS.DELETE_CREW,
    name: CONSTANTS.DELETE_CREW,
    className: "option-unassign",
    icon: React.createElement(CancelIcon),
  },
  {
    id: CONSTANTS.MASS_UPDATE_WO,
    name: CONSTANTS.MASS_UPDATE_WO,
    icon: React.createElement(PencilIcon),
  },
];
