// Import modules
import { Route, RouteProps } from "react-router-dom";
import { isAuthenticated } from "../utils/helper";
import React from "react";
/**
 * Redirects to Login Page if invalid AccessToken
 * if local environment - sets access token in Cookie
 * @NOTE - Use when AUTH WEB APP at localhost:3001 not availble, change env to live auth url
 */
/*
function LoginRedirect() {
  if (process.env.REACT_APP_ENVIRONMENT === 'local') {
    // eslint-disable-next-line no-alert
    const token = prompt('Enter Access Token');
    if (token) {
      const user = isAuthenticated(token);
      if (user) {
        SetCookie(COOKIE_NAME.accessToken, token, 0.5);
        SetUserDataCookie(user);
        window.location = '';
      }
    }
    RemoveCookies();
    return <Redirect to="/error-invalid-token" />;
  }
  window.location.href = LOGIN_URL;
}
*/

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>;
}

/**
 * if current user is authenticated - renders component
 * else redirects to login
 *
 
 */
const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteProps) => (
  <Route
    {...rest}
    render={(props: any) =>
      // #TODO check this logic again i have added this string
      isAuthenticated(null) ? <Component {...props} /> : null
    }
  />
);

export default PrivateRoute;
