import classes from "./unsupported_device.module.css";
import { ReactComponent as LaptopIcon } from "../../assets/icons/laptop.svg";
import ServiceActivatorLogo from "../../assets/images/logo_full.png";
import React from "react";

const UnsupportedDevice = () => {
  return (
    <div className={`unsupported-device-wrapper ${classes.wrapper}`}>
      <div className={classes.logo_wrapper}>
        <img
          src={ServiceActivatorLogo}
          alt="ServiceActivator"
          className={classes.img_logo}
        />
      </div>
      <div className={classes.wrapper_icon}>
        <LaptopIcon className={classes.icon} />
      </div>
      <div className={classes.message}>
        <br />
        <span className={classes.bold}> laptops&nbsp;&&nbsp;tablets</span>
      </div>
    </div>
  );
};

export { UnsupportedDevice };
