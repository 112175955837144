// crewSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { iCrew } from "../../types";
import { deleteCrew, fetchCrewList, postCrew } from "../thunk/crewApi";

interface ICrewState {
  crewList: iCrew[];
  crewListLoading: boolean;
  crewListError: string;
  crewPostLoading: boolean;
  crewPostData: string;
  crewPostError: string;
  crewDeleteLoading: boolean;
  crewDeleteData: string;
  crewDeleteError: string;
  existingCrewId: string | null;
  openCreateCrewModal: boolean;
}

export const InitalCrew: iCrew = {
  technician2EmployeeId: null,
  endDate: "",
  technician3EmployeeName: null,
  technician4EmployeeId: null,
  crewName: "",
  technician4EmployeeName: null,
  technician3EmployeeId: null,
  updatedAt: "",
  startDate: "",
  technician2EmployeeName: null,
  technician1EmployeeName: "",
  technician1EmployeeId: "",
  equipments: [], // Assum
};

const initialState: ICrewState = {
  crewList: [],
  crewListLoading: false,
  crewListError: "",
  crewPostLoading: false,
  crewPostData: "",
  crewPostError: "",
  crewDeleteLoading: false,
  crewDeleteData: "",
  crewDeleteError: "",
  existingCrewId: null,
  openCreateCrewModal: false,
};

const crewSlice = createSlice({
  name: "crew",
  initialState,
  reducers: {
    setCrewList: (state, action: PayloadAction<iCrew[]>) => {
      state.crewList = action.payload;
    },
    setExistingCrewId: (state, action: PayloadAction<string | null>) => {
      state.existingCrewId = action.payload;
    },
    setOpenCreateCrewModal: (state, action: PayloadAction<boolean>) => {
      state.openCreateCrewModal = action.payload;
    },
    clearCrewPostData: (state) => {
      state.crewPostData = "";
      state.crewPostError = "";
    },
    clearCrewDeleteData: (state) => {
      state.crewDeleteData = "";
      state.crewDeleteError = "";
    },
  },
  extraReducers: (Builder) => {
    //List reducer
    Builder.addCase(fetchCrewList.pending, (state, action) => {
      return {
        ...state,
        crewListLoading: true,
      };
    });
    Builder.addCase(fetchCrewList.fulfilled, (state, action) => {
      return {
        ...state,
        crewListLoading: false,
        crewList: action.payload,
      };
    });
    Builder.addCase(fetchCrewList.rejected, (state, action) => {
      return {
        ...state,
        crewListLoading: false,
        crewListError: action.payload
          ? action.payload
          : "OOPS! Internal server error",
      };
    });

    //Post Reducers
    Builder.addCase(postCrew.pending, (state) => {
      return {
        ...state,
        crewPostLoading: true,
      };
    });
    Builder.addCase(postCrew.fulfilled, (state, action) => {
      return {
        ...state,
        crewPostLoading: false,
        crewPostData: action.payload.crewId,
      };
    });
    Builder.addCase(postCrew.rejected, (state, action) => {
      return {
        ...state,
        crewPostLoading: false,
        crewPostError: action.payload
          ? action.payload
          : "OOPS! Internal server error",
      };
    });

    //Post Reducers Data
    Builder.addCase(deleteCrew.pending, (state) => {
      return {
        ...state,
        crewDeleteLoading: true,
      };
    });
    Builder.addCase(deleteCrew.fulfilled, (state, action) => {
      return {
        ...state,
        crewDeleteLoading: false,
        crewDeleteData: action.payload.errorDescription ? "" : "Crew Deleted", // in case of crew delete success, errorDescription will be empty but in case of warning it will have some value
        crewDeleteError: action.payload.errorDescription,
      };
    });
    Builder.addCase(deleteCrew.rejected, (state, action) => {
      return {
        ...state,
        crewDeleteLoading: false,
        crewDeleteError: action.payload
          ? action.payload
          : "OOPS! Internal server error",
      };
    });
  },
});

export const {
  setCrewList,
  setExistingCrewId,
  setOpenCreateCrewModal,
  clearCrewDeleteData,
  clearCrewPostData,
} = crewSlice.actions;

export default crewSlice.reducer;
