import { createGlobalStyle, css, keyframes } from "styled-components";

export const RoundedWhiteStyle = css`
  background: ${({ theme }: any) => theme.colors.white};
  border-radius: 6.52px;
`;

export const FlexCenterStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const FadeInAnimation = keyframes`
  from {opacity: 0;}
  to {opacity: 1;}
`;

export const SlideInAnimation = keyframes`
from {left: -100%;}
to {left: 0;}
`;

const GlobalStyles = createGlobalStyle`${({ theme }: any) => css`
  html,
  body,
  #root {
    height: 100%;
    min-height: 100%;
    background: #f3f3f7;
  }

  #root {
    max-height: 100vh;
    & > *:only-child {
      height: 100%;
      & *:not(.unsupported-device-wrapper, .unsupported-device-wrapper *) {
        @media (max-width: 480px) {
          display: none;
        }
      }
    }
  }

  * {
    &,
    &:after,
    &:before {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }
    font-family: "Avenir Next LT Pro";
    /* -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
    scrollbar-width: thin;
    scrollbar-color: #888888 #f5f5f5;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
      height: 0.8rem;
      width: 0.4rem;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #888888;
      border-radius: 20px;
      &:hover {
        background-color: #666666;
      }
    }
    &::-webkit-scrollbar-corner {
      background: none;
    }
  }

  .w-100 {
    width: 100%;
  }

  .d-flex {
    display: flex;
    flex-direction: row;
  }
  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .flex-separate {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .fdir-column {
    display: flex;
    flex-direction: column;
  }
  .align-end {
    align-items: flex-end;
  }
  .wrap {
    flex-wrap: wrap;
  }

  .m-05 {
    margin: 0.5rem;
  }
  .m-1 {
    margin: 1rem;
  }
  .m-2 {
    margin: 2rem;
  }
  .m-3 {
    margin: 3rem;
  }
  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .MuiButtonBase-root.MuiPickersDay-root,
  .PrivatePickersYear-yearButton,
  .PrivatePickersMonth-root {
    font-size: 14px;
    line-height: 17px;
    color: #595959;
    font-weight: normal;
    &.MuiPickersDay-today:not(.Mui-selected) {
      border-color: #59595980;
    }
    &.Mui-selected {
      font-weight: 600;
      color: ${theme.colors.white};
      background: ${theme.colors.green} !important;
      border-top: 2px solid transparent;
    }
  }

  .menu-card,
  .menu-technician-column {
    .MuiPaper-root {
      border-radius: 6px;
    }
    ul {
      padding: 4px 0 0;
    }
    li {
      padding: 10px 14px;
      color: ${theme.colors.green};
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      &.option-unassign {
        color: #f47458;
      }
      .option-icon-wrapper {
        ${FlexCenterStyle};
        & + .option-name {
          margin-left: 8px;
        }
      }
    }
  }
`}
`;

export default GlobalStyles;
