// Equipmentslice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { iEquipment } from "../../types";
import { fetchEquipmentList } from "../thunk/equipmentApi";

interface IEquipmentState {
  equipmentList: iEquipment[];
  equipmentListLoading: boolean;
  equipmentListError: string;
}

const initialState: IEquipmentState = {
  equipmentList: [],
  equipmentListError: "",
  equipmentListLoading: false,
};

const equipmentSlice = createSlice({
  name: "equipment",
  initialState,
  reducers: {},
  extraReducers: (Builder) => {
    //List reducer
    Builder.addCase(fetchEquipmentList.pending, (state, action) => {
      return {
        ...state,
        equipmentListLoading: true,
      };
    });
    Builder.addCase(fetchEquipmentList.fulfilled, (state, action) => {
      return {
        ...state,
        equipmentListLoading: false,
        equipmentList: action.payload.data,
        equipmentListError: "",
      };
    });
    Builder.addCase(fetchEquipmentList.rejected, (state, action) => {
      return {
        ...state,
        equipmentListLoading: false,
        equipmentListError: action.payload
          ? action.payload
          : "OOPS! Internal server error",
      };
    });
  },
});

export const {} = equipmentSlice.actions;

export default equipmentSlice.reducer;
