import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  iWorkOrderListResponse,
  IthunkAPI,
  IWrokorderQueryParam,
  iWorkOrder,
  IWorkorderPost,
  IWorkOrderPostPayload,
  IWorkOrderListPostPayload,
  IWorkorderListPost,
} from "../../types";
import { BearerToken } from "../../services/auth";
import { Auth } from "aws-amplify";
import { clearWorkorderError } from "../slices/workorderSlice";

export const fetchWorkorderList = createAsyncThunk<
  iWorkOrderListResponse,
  Partial<IWrokorderQueryParam>,
  IthunkAPI
>("workorder/fetchWorkorderList", async (params, thunkAPI) => {
  const { dispatch, rejectWithValue } = thunkAPI;
  //   const token = await dispatch(getToken());
  const token = BearerToken();
  try {
    let pageNo = 1;
    let allWorkOrders: iWorkOrder[] = [];
    let hasMorePages = true;
    while (hasMorePages) {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/workorders/list`,
        {
          params: { ...params, pageNo },
          headers: {
            //   Authorization: `Bearer ${token}`,
            Authorization: token,
          },
        }
      );
      const { data } = response.data;
      hasMorePages =
        response.data.meta.page.currentPage < response.data.meta.page.lastPage;
      pageNo++;
      allWorkOrders = allWorkOrders.concat(data);
    }
    return {
      data: allWorkOrders,
    };
  } catch (err: any) {
    if (axios.isCancel(err)) {
      // Handle cancellation separately
      return rejectWithValue({ canceled: true });
    } else {
      // Handle other errors
      return rejectWithValue(err?.response?.data?.errorDescription);
    }
  }
});

export const postWorkOrder = createAsyncThunk<
  IWorkorderPost,
  IWorkOrderPostPayload,
  IthunkAPI
>("workorder/postWorkOrder", async (payload, thunkAPI) => {
  const { dispatch, rejectWithValue } = thunkAPI;
  dispatch(clearWorkorderError());
  let { data, id, params } = payload;
  const url = `${process.env.REACT_APP_API_URL}/workorders/${id}`;
  if (data.isUpdateTechniciansOnFutureWorkOrders) {
    params = { ...params, isUpdateTechniciansOnFutureWorkOrders: true };
  }

  try {
    // const token = await dispatch(getToken());
    const token = BearerToken();
    const response = await axios.post(url, data, {
      params: params,
      headers: {
        // Authorization: `Bearer ${token}`
        Authorization: token,
      },
    });
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error?.response?.data?.errorDescription);
  }
});

export const postWorkOrderList = createAsyncThunk<
  IWorkorderListPost,
  IWorkOrderListPostPayload,
  IthunkAPI
>("workorder/postWorkOrderList", async (payload, thunkAPI) => {
  const { dispatch, rejectWithValue } = thunkAPI;
  dispatch(clearWorkorderError());
  const url = `${process.env.REACT_APP_API_URL}/workorders/list`;
  let { data } = payload;
  try {
    // const token = await dispatch(getToken());
    const token = BearerToken();
    const response = await axios.post(url, data, {
      headers: {
        // Authorization: `Bearer ${token}`
        Authorization: token,
      },
    });
    let workOrderListResponse: IWorkorderListPost;

    workOrderListResponse = {
      isWorkOrderListPostSuccessful: true,
    };

    return workOrderListResponse;
  } catch (error: any) {
    return rejectWithValue(error?.response?.data?.errorDescription);
  }
});
