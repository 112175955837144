// import modules
import {
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material/styles";
import { ThemeProvider } from "styled-components";
import { BrowserRouter } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Authenticator } from "aws-amplify-react";
import Routes from "./navigation/routes";
import { UnsupportedDevice } from "./pages/UnsupportedDevice";

// import styles
import "./assets/fonts/Avenir Next LT Pro/avenir_next.css";
import GlobalStyles from "./GlobalStyles";
import MuiTheme from "./utils/theme";

// Import Misc
import { isAuthenticated } from "./utils/helper";
import { LoginHandler } from "./services/auth";
// import { CrewProvider } from "./context/crew_context";
import React from "react";
import store from "./store";
import { Provider } from "react-redux";

function App() {
  if (window?.innerWidth < 480) {
    return (
      <>
        <UnsupportedDevice />{" "}
      </>
    );
  }

  const user = isAuthenticated(null);
  // Redirect to Login if no session found
  if (!user) {
    LoginHandler();
  }

  return (
    <Provider store={store}>
      <MuiThemeProvider theme={MuiTheme}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={MuiTheme}>
            <Authenticator hideDefault>
              <UnsupportedDevice />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                {/* <CrewProvider> */}
                <BrowserRouter>
                  <GlobalStyles />
                  <Routes />
                </BrowserRouter>
                {/* </CrewProvider> */}
              </LocalizationProvider>
            </Authenticator>
          </ThemeProvider>
        </StyledEngineProvider>
      </MuiThemeProvider>
    </Provider>
  );
}

export default App;
