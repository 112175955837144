// import modules
import { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";

// import components
import PrivateRoute from "./private_route";
import React from "react";

const Scheduler = lazy(() => import("../pages/Scheduler"));
const Error404 = lazy(() => import("../pages/Error404"));

const Routes = () => (
  <Suspense fallback={<div className="initial-loader" />}>
    <Switch>
      <PrivateRoute
        exact
        path={["/", "/home", "board", "/scheduler"]}
        component={Scheduler}
      />

      <Route component={Error404} />
    </Switch>
  </Suspense>
);

export default Routes;
