// import modules

import DecodeJWT from "jwt-decode";
import moment from "moment";
import { CONSTANTS } from "./constants";
import {
  COOKIE_DOMAIN,
  COOKIE_NAME,
  MAPS_URL,
  MSA_APP_URL,
} from "../services/config";

import {
  iCrew,
  iDailySeqeuenceObj,
  iEquipment,
  iFilters,
  IMassUpdateProps,
  iTechnicians,
  iWarning,
  iWorkOrder,
  IWorkOrderFilters,
  ProcessedWorkOrders,
  // iWorkOrderDeltaState,
} from "../types";

import {
  filterWorkorders,
  setAssignedWorkOrders,
  setUnassignedWorkOrders,
} from "../store/slices/workorderSlice";
import { Dispatch } from "redux";
import _ from "lodash";

// Method to format date string to MM/DD/YYYY format
export const formatDateForUI = (dateString: any) => {
  if (dateString) {
    return moment(dateString, "YYYYMMDD").format("MM/DD/YYYY");
  }
  return "";
};

// Import Constants

/**
 * Converts unix timestamp into JS Date string
 *
 * @param {unixTimeStamp} unixTimeStamp
 * @returns {String} - language specific representation of Date
 */
export const ConvertDateTime = (unixTimeStamp: any) => {
  const date = new Date(unixTimeStamp * 1000);
  return date.toLocaleString();
};

/**
 * Adjust Date to Current TimeZone
 *
 * @param {Date|string} date - date or valid date string
 * @returns {Date} - adjusted date to the current TimeZone
 */
export const AdjustDate = (date: any) => {
  date = date.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3");
  const dt = new Date(date);
  return new Date(dt.getTime() + dt.getTimezoneOffset() * 60 * 1000);
};

/**
 * Stores cookie for the specific domain
 *
 * @param  {string} cName - Cookie name
 * @param  {string} cValue - Cookie Value
 * @param  {number} expDay - Number of days for cookie expiration Default=0.5
 */
export const SetCookie = (cName: any, cValue: any, expDay = 0.5) => {
  // encode cookie before storing it
  cValue = encodeURIComponent(cValue);
  // Set cookie expiry time to 'expDay' days (default: 0.5 day)
  const time = new Date(
    Date.now() + expDay * 24 * 60 * 60 * 1000
  ).toUTCString();
  document.cookie = `${cName}=${cValue}; path=/; domain=${COOKIE_DOMAIN};expires=${time}`;
};

/**
 * Finds a Specific Cookie or returns All the Cookies
 *
 * @param  {string} cName=null - Cookie Name to find
 * @returns {string|object} - if cookie not found, all cookies are returned
 */
export const GetCookie = ({ cName = "" }: { cName: string }) => {
  const cookies: { [key: string]: string } = document.cookie
    .split(";")
    .map((cookie) => cookie.split("="))
    .reduce(
      (cookieObj, [key, value]) => ({
        ...cookieObj,
        ...(process.env.REACT_APP_COOKIE_PREFIX &&
          key.indexOf(process.env.REACT_APP_COOKIE_PREFIX) >= 0 && {
            [key.trim()]: decodeURIComponent(value),
          }),
      }),
      {}
    );
  if (cName === "") return cookies;
  return cookies[cName];
};

/**
 * Removes cookies
 */
export const RemoveCookies = () => {
  const cookieToBeRemoved = [
    COOKIE_NAME.accessToken,
    COOKIE_NAME.accountId,
    COOKIE_NAME.accountName,
    COOKIE_NAME.userEmail,
    COOKIE_NAME.userPhone,
    COOKIE_NAME.expiresAt,
  ];
  document.cookie.split(";").forEach((cookie) => {
    let name = cookie.split("=")?.[0] || cookie;
    name = name.trim();
    if (cookieToBeRemoved.includes(name)) {
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=${COOKIE_DOMAIN}`;
    }
  });
};

/**
 * Sets Cookies with User Data
 *
 * @param  {object} user - object containing user data
 * @returns {object} contains all cookies
 */
export const SetUserDataCookie = (user: any) => {
  SetCookie(COOKIE_NAME.accountId, user["custom:id"], 0.5);
  SetCookie(COOKIE_NAME.accountName, user["custom:accountName"], 0.5);
  SetCookie(COOKIE_NAME.userEmail, user.email, 0.5);
  SetCookie(COOKIE_NAME.userPhone, user.phone_number, 0.5);
  SetCookie(COOKIE_NAME.expiresAt, ConvertDateTime(user.exp), 0.5);
  return GetCookie({ cName: "" });
};

/**
 * Checks if acccessToken is valid and returns false or decoded token data
 *
 * @returns {boolean|object} is either false or decoded user data from token
 */
export const isAuthenticated = (token: any) => {
  const accessToken: any =
    token || GetCookie({ cName: COOKIE_NAME.accessToken });
  if (accessToken && accessToken !== "null") {
    try {
      const data: any = DecodeJWT(accessToken);
      if (Date.now() < data?.exp * 1000) {
        return data;
      }
    } catch (e) {
      console.error(e);
    }
  }
  RemoveCookies();
  return false;
};

/**
 * Log Error message or object
 *
 * @param {Error} e - Error object
 * @param {string} logPrefix - to print the message before logging error
 * @returns {object} - contains message property of string type
 */
export const GetError = (error: any, logPrefix = "") => {
  const { response, request, config, message } = error;
  if (response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const errorData = response.data;
    console.error(logPrefix, response);
    return {
      code: errorData?.errorCode,
      message:
        errorData?.errorDescription || errorData?.message || "Internal Error",
      responseStatus: response.status,
      request: config
        ? {
            url: config.url,
            data: (config.data && JSON.parse(config.data)) || null,
          }
        : null,
    };
  }
  if (request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.error(logPrefix, request);
    return { message: "No response." };
  }
  // Something happened in setting up the request that triggered an Error
  // console.error(error.config);
  console.error("Error", logPrefix, message);

  return { message: message || "Something went wrong" };
};

/**
 * Sorts array of objects by key in alphabatical order
 *
 * @param {array} arr - array of objects
 * @param {string} field - object property to sort
 * @returns {array} - array by sorted alphabetical order
 */
export const SortArrayByKey = (arr: any, key: any) =>
  arr.sort((a: any, b: any) => {
    const textA = a[key]?.toUpperCase();
    const textB = b[key]?.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

/**
 * compareFunction for dailySequenceNumber
 *
 * @param {object} task Objext
 * @param {object} NextTask Object
 * @returns {Boolean} - CompareFunction for dailySequenceNumber of task
 */

export const CompareDailySeqNum = (
  a: iWorkOrder,
  b: iWorkOrder,
  userId: string
) => {
  // // Define status priorities
  // const statusPriority = {
  //   [CONSTANTS.IN_PROGRESS]: 1,
  //   [CONSTANTS.PENDING]: 2,
  //   [CONSTANTS.COMPLETED]: 3,
  //   [CONSTANTS.INCOMPLETE]: 4,
  // };

  // // Compare statuses first
  // if (a.status !== b.status) {
  //   return statusPriority[a.status ?? ""] - statusPriority[b.status ?? ""];
  // }

  // // Handle sorting within the same status
  // switch (a.status) {
  //   case CONSTANTS.IN_PROGRESS:
  //   case CONSTANTS.COMPLETED:
  //   case CONSTANTS.INCOMPLETE:
  //     // For both in progress and completed, sort by job name first
  //     const jobNameComparison = (a.job?.name || "").localeCompare(
  //       b.job?.name || ""
  //     );
  //     if (jobNameComparison !== 0) {
  //       return jobNameComparison;
  //     }
  //     // For completed status, if job names are the same, sort by daily sequence
  //     return (
  //       getDailySequenceNumber(a, userId) - getDailySequenceNumber(b, userId)
  //     );

  //   case CONSTANTS.PENDING:
  //     // For pending, sort by daily sequence first
  //     const dailySequenceComparison =
  //       getDailySequenceNumber(a, userId) - getDailySequenceNumber(b, userId);
  //     if (dailySequenceComparison !== 0) {
  //       return dailySequenceComparison;
  //     }
  //     // If daily sequences are the same, sort by job name
  //     return (a.job?.name || "").localeCompare(b.job?.name || "");

  //   default:
  //     // Default case should not be reached if all statuses are covered
  //     return 0;
  // }

  // For pending, sort by daily sequence first
  const dailySequenceComparison =
    getDailySequenceNumber(a, userId) - getDailySequenceNumber(b, userId);
  if (dailySequenceComparison !== 0) {
    return dailySequenceComparison;
  }
  // If daily sequences are the same, sort by job name
  return (a.job?.name || "").localeCompare(b.job?.name || "");
};

// Helper function to get daily sequence number based on userId
function getDailySequenceNumber(workOrder: iWorkOrder, userId: string): number {
  if (workOrder?.technician1Employee?.id === userId) {
    return workOrder.dailySequenceTech1 ?? 100;
  } else if (workOrder?.technician2Employee?.id === userId) {
    return workOrder.dailySequenceTech2 ?? 100;
  } else if (workOrder?.technician3Employee?.id === userId) {
    return workOrder.dailySequenceTech3 ?? 100;
  } else if (workOrder?.technician4Employee?.id === userId) {
    return workOrder.dailySequenceTech4 ?? 100;
  } else if (userId == CONSTANTS.UNASSIGN) {
    return workOrder.dailySequenceTech1 ?? 100;
  }
  return 100; // Default to 0 if no match
}
export const CompareDailySeqNumForCrew = (crew1Task: any, crew2Task: any) => {
  let dailySequenceCrew1 = 0;
  let dailySequenceCrew2 = 0;

  if (crew1Task?.technician1Employee?.id) {
    dailySequenceCrew1 += crew1Task.dailySequenceTech1;
  }
  if (crew1Task?.technician2Employee?.id) {
    dailySequenceCrew1 += crew1Task.dailySequenceTech2;
  }
  if (crew1Task?.technician3Employee?.id) {
    dailySequenceCrew1 += crew1Task.dailySequenceTech3;
  }
  if (crew1Task?.technician4Employee?.id) {
    dailySequenceCrew1 += crew1Task.dailySequenceTech4;
  }

  if (crew2Task?.technician1Employee?.id) {
    dailySequenceCrew2 += crew2Task.dailySequenceTech1;
  }
  if (crew2Task?.technician2Employee?.id) {
    dailySequenceCrew2 += crew2Task.dailySequenceTech2;
  }
  if (crew2Task?.technician3Employee?.id) {
    dailySequenceCrew2 += crew2Task.dailySequenceTech3;
  }
  if (crew2Task?.technician4Employee?.id) {
    dailySequenceCrew2 += crew2Task.dailySequenceTech4;
  }

  // Update this code to check Name of task if daily sequence number is same and then sort by name and if names are sort then sort by id
  if (
    (dailySequenceCrew1 === null && dailySequenceCrew2 === null) ||
    dailySequenceCrew1 === dailySequenceCrew2
  ) {
    // if names are not equal then sort by name else sort by id
    if (crew1Task.name === crew2Task.name) {
      return crew1Task.id - crew2Task.id;
    }
    return crew1Task.name - crew2Task.name;
  }

  return dailySequenceCrew1 - dailySequenceCrew2;
};

/**
 * Sort crew in alphabetical order
 * @param {Object} crew1
 * @param {Object} crew2
 * @returns
 */
export const SortCrewInAlphabeticalOrder = (crew1: any, crew2: any) => {
  const crew1Name = crew1.crewName.toLowerCase();
  const crew2Name = crew2.crewName.toLowerCase();

  if (crew1Name < crew2Name) return -1;
  if (crew1Name > crew2Name) return 1;
  return 0;
};

/**
 * Check if parent array includes all element of sub array
 *
 * @param {array} arr - parent array
 * @param {array} subArr - sub array
 * @returns {boolean} - is array child of parent
 */
export const ArrayIncludesSubArray = (arr: any, subArr: any) =>
  subArr.every((v: any) => arr.includes(v));

/**
 * Remove WorkOrder of assigned technician
 *
 * @param {object} assignedWorkOrders - object of assigned workorder
 * @param {array} technician - technician to remove the task from
 * @param {string} taskId - task to remove from assigned technician
 * @returns {object} - updated assigned work order
 */
export const RemoveAssignedTask = (
  temp_assignedWorkOrders: Map<string, iWorkOrder[]>,
  technicianId: string,
  taskId: string
) => {
  const existingTask = temp_assignedWorkOrders
    .get(technicianId)
    ?.filter((t: any) => t.id === taskId)[0];
  const updatedTasksArr = temp_assignedWorkOrders
    .get(technicianId)
    ?.filter((t: any) => t.id !== taskId);
  let newAssignWorkOrders = new Map(temp_assignedWorkOrders);
  newAssignWorkOrders.set(technicianId, updatedTasksArr ?? []);

  // Removing card from other technicians also
  if (existingTask?.technician2Employee?.id) {
    const techUpdatedTask = temp_assignedWorkOrders
      .get(existingTask?.technician2Employee?.id)
      ?.filter((t: any) => t.id !== taskId);
    newAssignWorkOrders.set(
      existingTask?.technician2Employee?.id,
      techUpdatedTask ?? []
    );
  }

  if (existingTask?.technician3Employee?.id) {
    const techUpdatedTask = temp_assignedWorkOrders
      .get(existingTask?.technician3Employee?.id)
      ?.filter((t: any) => t.id !== taskId);
    newAssignWorkOrders.set(
      existingTask?.technician3Employee?.id,
      techUpdatedTask ?? []
    );
  }

  if (existingTask?.technician4Employee?.id) {
    const techUpdatedTask = temp_assignedWorkOrders
      .get(existingTask?.technician4Employee?.id)
      ?.filter((t: any) => t.id !== taskId);
    newAssignWorkOrders.set(
      existingTask?.technician4Employee?.id,
      techUpdatedTask ?? []
    );
  }

  return newAssignWorkOrders;
};

/**
 * Remove WorkOrder of assigned crew
 *
 */
export const RemoveAssignedTaskInCrew = (
  temp_assignedWorkOrders: Map<string, iWorkOrder[]>,
  taskId: string,
  inCrewDetail?: iCrew,
  crewList?: iCrew[],
  crewId?: string
) => {
  const crewDetail = crewId
    ? crewList && crewList.find((cl: any) => cl.crewId === crewId)
    : inCrewDetail;

  const selectedTask = crewDetail?.technician1EmployeeId
    ? temp_assignedWorkOrders
        .get(crewDetail.technician1EmployeeId)
        ?.filter((t: any) => t.id === taskId)[0]
    : null;

  const restOfTasks = crewDetail?.technician1EmployeeId
    ? temp_assignedWorkOrders
        .get(crewDetail.technician1EmployeeId)
        ?.filter((t: any) => t.id !== taskId)
    : [];

  let newAssignWorkOrders = new Map(temp_assignedWorkOrders);
  selectedTask?.technician1Employee?.id &&
    newAssignWorkOrders.set(
      selectedTask.technician1Employee?.id,
      restOfTasks ?? []
    );

  // Removing card from other technicians also
  if (selectedTask?.technician2Employee?.id) {
    const techUpdatedTask = temp_assignedWorkOrders
      .get(selectedTask?.technician2Employee?.id)
      ?.filter((t: any) => t.id !== taskId);
    newAssignWorkOrders.set(
      selectedTask?.technician2Employee?.id,
      techUpdatedTask ?? []
    );
  }

  // Removing card from other technicians also
  if (selectedTask?.technician3Employee?.id) {
    const techUpdatedTask = temp_assignedWorkOrders
      .get(selectedTask?.technician3Employee?.id)
      ?.filter((t: any) => t.id !== taskId);
    newAssignWorkOrders.set(
      selectedTask?.technician3Employee?.id,
      techUpdatedTask ?? []
    );
  }

  // Removing card from other technicians also
  if (selectedTask?.technician4Employee?.id) {
    const techUpdatedTask = temp_assignedWorkOrders
      .get(selectedTask?.technician4Employee?.id)
      ?.filter((t: any) => t.id !== taskId);
    newAssignWorkOrders.set(
      selectedTask?.technician4Employee?.id,
      techUpdatedTask ?? []
    );
  }

  return newAssignWorkOrders;
};

/**
 * Opens page in new tab for specified id
 *
 * @param {string} id - page to open
 * @returns new Tab in browser
 */
export const OpenMSAPage = (id: any) =>
  window.open(`${MSA_APP_URL}/jobs/${id}`, "_blank");
export const OpenMSAPageWork = (id: any) =>
  window.open(`${MSA_APP_URL}/workorders/${id}`, "_blank");

/**
 * Opens Maps page in new Tab for Proprty Id
 *
 * @param {sting} id - property Id
 * @returns new Tab in browser with property location
 */
export const OpenPropertyOnMaps = (id: any) =>
  window.open(`${MAPS_URL}/geofence?propertyId=${id}`, "_blank");

/**
 * Get Status of Work order
 *
 * @param {object} task - workorder object
 * @returns {string} status of workorder
 */
export const GetTaskStatus = (task: any) => {
  let status = CONSTANTS.PENDING;
  if (!task.checkInTime && !task.checkOutTime) {
    status = CONSTANTS.PENDING;
  }
  if (task.checkInTime && !task.checkOutTime) {
    status = CONSTANTS.IN_PROGRESS;
  }
  // @TODO: task.isComplete field is already available
  if (task.checkInTime && task.checkOutTime) {
    status = CONSTANTS.COMPLETED;
  }
  // @TODO: task cancelled or incomplete?
  if (task.isIncompleteService) {
    status = CONSTANTS.CANCELLED;
  }
  return status;
};

/**
 * Get Color for workorder status
 *
 * @param {string} status - workorder status
 * @returns {string} color Code of of the status
 */
export const GetStatusColor = (status: any) => {
  switch (status) {
    case CONSTANTS.PENDING:
      return "#F28E1A";

    case CONSTANTS.IN_PROGRESS:
      return "#88A13B";

    case CONSTANTS.COMPLETED:
      return "#2E5EAA";

    case CONSTANTS.INCOMPLETE:
      return "#f0340a";

    case CONSTANTS.CANCELLED:
      return "#f0340a";

    default:
      return "#F28E1A";
  }
};

/**
 * Get Updated AWO
 * When work order is moving from unassign to assign
 *
 * @param {object} list - existing assign work orders
 * @param {object} currentAWO - the work order to update
 * @returns {object} the updated assign work order list
 */
export const GetAWOForAddNewWorkOrder = (
  list: Map<string, iWorkOrder[]>,
  currentAWO: iWorkOrder
): Map<string, iWorkOrder[]> => {
  let updatedAWO = new Map(list);

  if (currentAWO.technician1Employee?.id)
    updatedAWO = ReplaceOrAddWorkOrder(
      updatedAWO,
      currentAWO,
      currentAWO.technician1Employee?.id || ""
    );

  if (currentAWO.technician2Employee)
    updatedAWO = ReplaceOrAddWorkOrder(
      updatedAWO,
      currentAWO,
      currentAWO.technician2Employee?.id || ""
    );

  if (currentAWO.technician3Employee)
    updatedAWO = ReplaceOrAddWorkOrder(
      updatedAWO,
      currentAWO,
      currentAWO.technician3Employee?.id || ""
    );

  if (currentAWO.technician4Employee)
    updatedAWO = ReplaceOrAddWorkOrder(
      updatedAWO,
      currentAWO,
      currentAWO.technician4Employee?.id || ""
    );

  return updatedAWO;
};

//Replace or Add WorkOrder in the list
export const ReplaceOrAddWorkOrder = (
  list: Map<string, iWorkOrder[]>,
  currentAWO: iWorkOrder,
  technicianId: string
): Map<string, iWorkOrder[]> => {
  let updatedAWO = new Map(list);
  const findIsTaskExistforTech: number =
    list
      .get(technicianId)
      ?.findIndex((workorder: any) => workorder.id === currentAWO.id) ?? -1;

  if (findIsTaskExistforTech > -1) {
    updatedAWO.set(
      technicianId,
      list
        .get(technicianId)
        ?.map((workorder: any) =>
          workorder.id === currentAWO.id ? { ...currentAWO } : workorder
        ) ?? []
    );
  } else {
    updatedAWO.set(technicianId, [
      ...(list.get(technicianId) ?? []),
      currentAWO,
    ]);
  }
  return updatedAWO;
};

/**
 * Get Updated AWO when assign between two crews.
 */
export const GetAWOWhenAsgnBetnCrew = (
  list: Map<string, iWorkOrder[]>,
  task: iWorkOrder,
  newCrew: iCrew,
  extraFields: any,
  technicians: iTechnicians[]
): Map<string, iWorkOrder[]> => {
  const currentAWO: iWorkOrder = {
    ...task,
    ...{ crewId: newCrew.crewId },
    ...extraFields,
    dailySequenceTech1: task.dailySequenceTech1,
  };
  // currentAWO.technicianUser1 = newCrew.technician1EmployeeName;
  // currentAWO.technicianUser1UserId = newCrew.technicianUser1UserId;
  populateEmployeeDetailsFromTechnician(
    newCrew.technician1EmployeeId,
    currentAWO,
    technicians
  );
  // currentAWO.technician1EmployeeId = newCrew.technician1EmployeeId;
  if (newCrew?.technician2EmployeeId) {
    populateEmployeeDetailsFromTechnician(
      newCrew.technician2EmployeeId,
      currentAWO,
      technicians,
      "Employee2"
    );
    // currentAWO.technician2EmployeeId = newCrew?.technician2EmployeeId;
    // currentAWO.technicianUser2 = newCrew?.technician2EmployeeName;
    // currentAWO.technician2EmployeeId = newCrew?.technician2EmployeeId;
  } else {
    currentAWO.technician2Employee = null;
    // currentAWO.technicianUser2 = null;
    // currentAWO.technicianUser2UserId = null;
  }

  if (newCrew?.technician3EmployeeId) {
    populateEmployeeDetailsFromTechnician(
      newCrew.technician3EmployeeId,
      currentAWO,
      technicians,
      "Employee3"
    );
    // currentAWO.technician3EmployeeId = newCrew?.technician3EmployeeId;
    // currentAWO.technicianUser3 = newCrew?.technician3EmployeeName;
    // currentAWO.technicianUser3UserId = newCrew?.technicianUser3UserId;
  } else {
    currentAWO.technician3Employee = null;
    // currentAWO.technicianUser3 = null;
    // currentAWO.technicianUser3UserId = null;
  }

  if (newCrew?.technician4EmployeeId) {
    populateEmployeeDetailsFromTechnician(
      newCrew.technician4EmployeeId,
      currentAWO,
      technicians,
      "Employee4"
    );
    // currentAWO.technician4EmployeeId = newCrew?.technician4EmployeeId;
    // currentAWO.technicianUser4 = newCrew?.technician4EmployeeName;
    // currentAWO.technicianUser4UserId = newCrew?.technicianUser4UserId;
  } else {
    currentAWO.technician4Employee = null;
    // currentAWO.technicianUser4 = null;
    // currentAWO.technicianUser4UserId = null;
  }

  // First we remove the task which is assign to previous technicians
  let removeFromPreviousTechs: Map<string, iWorkOrder[]> = new Map();

  task.technician1Employee?.id &&
    removeFromPreviousTechs.set(
      task.technician1Employee?.id,
      list
        .get(task.technician1Employee?.id)
        ?.filter((t: any) => t.id !== task.id) ?? []
    );

  if (task?.technician2Employee?.id) {
    removeFromPreviousTechs.set(
      task.technician2Employee?.id,
      list
        .get(task.technician2Employee?.id)
        ?.filter((t: any) => t.id !== task.id) ?? []
    );
  }
  if (task?.technician3Employee?.id) {
    removeFromPreviousTechs.set(
      task.technician3Employee?.id,
      list
        .get(task.technician3Employee?.id)
        ?.filter((t: any) => t.id !== task.id) ?? []
    );
  }

  if (task?.technician4Employee?.id) {
    removeFromPreviousTechs.set(
      task.technician4Employee?.id,
      list
        .get(task.technician4Employee?.id)
        ?.filter((t: any) => t.id !== task.id) ?? []
    );
  }

  // Now we create a new object for new assign technicians
  // Where we first check if the current task is already in the assign user array then we only update the service with current service
  // Otherwise add the task to previous all task or create new one.

  const listWithRemovedExistingAssignment = new Map([
    ...list,
    ...removeFromPreviousTechs,
  ]);

  let newCrewAssignment: Map<string, iWorkOrder[]> = new Map();

  if (newCrew.technician1EmployeeId)
    newCrewAssignment = new Map([
      ...newCrewAssignment,
      ...ReplaceOrAddWorkOrder(
        listWithRemovedExistingAssignment,
        currentAWO,
        newCrew.technician1EmployeeId
      ),
    ]);

  if (newCrew.technician2EmployeeId)
    newCrewAssignment = new Map([
      ...newCrewAssignment,
      ...ReplaceOrAddWorkOrder(
        listWithRemovedExistingAssignment,
        currentAWO,
        newCrew.technician2EmployeeId
      ),
    ]);

  if (newCrew.technician3EmployeeId)
    newCrewAssignment = new Map([
      ...newCrewAssignment,
      ...ReplaceOrAddWorkOrder(
        listWithRemovedExistingAssignment,
        currentAWO,
        newCrew.technician3EmployeeId
      ),
    ]);

  if (newCrew.technician4EmployeeId)
    newCrewAssignment = new Map([
      ...newCrewAssignment,
      ...ReplaceOrAddWorkOrder(
        listWithRemovedExistingAssignment,
        currentAWO,
        newCrew.technician4EmployeeId
      ),
    ]);

  const updatedAWO = new Map([...list, ...newCrewAssignment]);
  return updatedAWO;
};

/**
 * Get Updated AWO
 * When work order is moving from unassign to assign
 *
 * @param {object} currentCrew - current crew
 * @param {object} technicians - the list of technicians
 * @returns {number} a number for totalWorkingHours
 */
export const GetTotalAndAssignHourForCrew = (
  currentCrew: any,
  technicians: any
) => {
  const technician1 = technicians.find(
    (user: any) => user.id === currentCrew.technician1EmployeeId
  );
  let totalWorkingHours =
    (technician1?.totalWorkingHours ?? 0) > CONSTANTS.TOTAL_WORK_HOURS
      ? technician1.totalWorkingHours
      : CONSTANTS.TOTAL_WORK_HOURS;

  if (currentCrew?.technician2EmployeeId) {
    const technician2 = technicians.find(
      (user: any) => user.id === currentCrew.technician2EmployeeId
    );
    totalWorkingHours +=
      (technician2?.totalWorkingHours ?? 0) > CONSTANTS.TOTAL_WORK_HOURS
        ? technician2.totalWorkingHours
        : CONSTANTS.TOTAL_WORK_HOURS;
  }

  if (currentCrew?.technician3EmployeeId) {
    const technician3 = technicians.find(
      (user: any) => user.id === currentCrew.technician3EmployeeId
    );
    totalWorkingHours +=
      (technician3?.totalWorkingHours ?? 0) > CONSTANTS.TOTAL_WORK_HOURS
        ? technician3.totalWorkingHours
        : CONSTANTS.TOTAL_WORK_HOURS;
  }

  if (currentCrew?.technician4EmployeeId) {
    const technician4 = technicians.find(
      (user: any) => user.id === currentCrew.technician4EmployeeId
    );
    totalWorkingHours +=
      (technician4?.totalWorkingHours ?? 0) > CONSTANTS.TOTAL_WORK_HOURS
        ? technician4?.totalWorkingHours
        : CONSTANTS.TOTAL_WORK_HOURS;
  }

  return totalWorkingHours;
};

/**
 * Get Updated AWO
 * When work order is moving from unassign to assign
 *
 * @param {object} currentCrew - current crew
 * @returns {array} crew member name array
 */
export const GetCrewMemberArray = (currentCrew: any) => {
  const memberArray = [currentCrew.technician1EmployeeName];

  if (currentCrew.technician2EmployeeName) {
    memberArray.push(currentCrew.technician2EmployeeName);
  }
  if (currentCrew.technician3EmployeeName) {
    memberArray.push(currentCrew.technician3EmployeeName);
  }
  if (currentCrew.technician4EmployeeName) {
    memberArray.push(currentCrew.technician4EmployeeName);
  }

  return memberArray;
};

/**
 * Find user sequence number from list and userId
 * Here finding the dailySequence where the given user is belong.
 */
export const GetSequenceForUserId = (tasks: any, userId: any, index: any) => {
  let sequenceNumber;
  if (tasks[index]?.technician1Employee?.id === userId) {
    sequenceNumber = tasks[index]?.dailySequenceTech1 ?? 0;
  }
  if (tasks[index]?.technician2Employee?.id === userId) {
    sequenceNumber = tasks[index]?.dailySequenceTech2 ?? 0;
  }
  if (tasks[index]?.technician3Employee?.id === userId) {
    sequenceNumber = tasks[index]?.dailySequenceTech3 ?? 0;
  }
  if (tasks[index]?.technician4Employee?.id === userId) {
    sequenceNumber = tasks[index]?.dailySequenceTech4 ?? 0;
  }
  return sequenceNumber;
};

/**
 * Get dailySequenceNumber for all user.
 */
export const GetDailySequenceNumber = (list: any, techIds: any) => {
  const seqNumberObj: iDailySeqeuenceObj = {};

  if (techIds?.technician1EmployeeId) {
    const tasks = list[techIds?.technician1EmployeeId] ?? [];
    if (tasks?.length) {
      seqNumberObj.dailySequenceTech1 =
        GetSequenceForUserId(
          tasks,
          techIds?.technician1EmployeeId,
          tasks?.length - 1
        ) + 100;
    } else {
      seqNumberObj.dailySequenceTech1 = 100;
    }
  }

  if (techIds?.technician2EmployeeId) {
    const tasks = list[techIds?.technician2EmployeeId] ?? [];
    if (tasks?.length) {
      seqNumberObj.dailySequenceTech2 =
        GetSequenceForUserId(
          tasks,
          techIds?.technician2EmployeeId,
          tasks?.length - 1
        ) + 100;
    } else {
      seqNumberObj.dailySequenceTech2 = 100;
    }
  }

  if (techIds?.technician3EmployeeId) {
    const tasks = list[techIds?.technician3EmployeeId] ?? [];
    if (tasks?.length) {
      seqNumberObj.dailySequenceTech3 =
        GetSequenceForUserId(
          tasks,
          techIds?.technician3EmployeeId,
          tasks?.length - 1
        ) + 100;
    } else {
      seqNumberObj.dailySequenceTech3 = 100;
    }
  }

  if (techIds?.technician4EmployeeId) {
    const tasks = list[techIds?.technician4EmployeeId] ?? [];
    if (tasks?.length) {
      seqNumberObj.dailySequenceTech4 =
        GetSequenceForUserId(
          tasks,
          techIds?.technician4EmployeeId,
          tasks?.length - 1
        ) + 100;
    } else {
      seqNumberObj.dailySequenceTech4 = 100;
    }
  }

  return seqNumberObj;
};

/**
 * Get equipments in two - two group
 */
export const GetEquipmentsInSeparate = (equipmentList: iEquipment[]) => {
  const crewEquipments = [...equipmentList];
  const splittedArray = [];
  while (crewEquipments.length > 0) {
    splittedArray.push(crewEquipments.splice(0, 2));
  }

  return splittedArray;
};

/**
 * Get crew tech names with comma separate
 */
export const GetCrewTechNames = (crew: any) => {
  let names = crew?.technician1EmployeeName ?? crew?.technicianUser1 ?? "";
  if (crew?.technician2EmployeeName)
    names += `, ${crew?.technician2EmployeeName}`;
  if (crew?.technician3EmployeeName)
    names += `, ${crew?.technician3EmployeeName}`;
  if (crew?.technician4EmployeeName)
    names += `, ${crew?.technician4EmployeeName}`;
  if (crew?.technicianUser2) names += `, ${crew.technicianUser2}`;
  if (crew?.technicianUser3) names += `, ${crew.technicianUser3}`;
  if (crew?.technicianUser4) names += `, ${crew.technicianUser4}`;
  return names;
};

/**
 * Creating a total list of workorder
 * @param {object} assignWorkrders
 * @param {array} unAssignWorkorders
 * @returns array of total list of workorders
 */
export const CreateListOfWorkorders = (
  assignWorkrders: Map<string, iWorkOrder[]>,
  unAssignWorkorders: iWorkOrder[]
) => {
  const newAssignWorkorder: iWorkOrder[] = [];
  assignWorkrders.forEach((value, key) => {
    if (assignWorkrders.get(key)) {
      newAssignWorkorder.push(...assignWorkrders.get(key)!); // Add '!' to assert that the value is not undefined
    }
  });

  const allWorkOrders: iWorkOrder[] = [
    ...(newAssignWorkorder ?? []),
    ...(unAssignWorkorders ?? []),
  ];
  // In order to remove duplicate workorder.
  const workderIds = allWorkOrders.map((o) => o.id);
  const filteredWorkorders = allWorkOrders.filter(
    ({ id }, index) => !workderIds.includes(id, index + 1)
  );

  return filteredWorkorders;
};

// Function to modify the 'assign' Map within the hold work order state
export const handleHoldWorkOrderForUndoChanges = (
  setHoldWorkOrder: React.Dispatch<React.SetStateAction<iWorkOrder[]>>, // Pass the state setter function
  newWorkOrder: iWorkOrder
) => {
  let t_newWorkOrder = { ...newWorkOrder };
  setHoldWorkOrder((prevObj) => {
    // Clone the previous Map to ensure we're not mutating state directly
    if (!prevObj.find((t: any) => t.id === newWorkOrder.id)) {
      let updatedList = [...prevObj, t_newWorkOrder];
      return updatedList;
    }
    return prevObj; // Return the previous state if the condition is not met
  });
};

export const replaceAllWorkordersForaSpecificDate = ({
  temp_assignedWorkOrders,
  serviceDate,
  workOrders = [],
  technicianId,
}: {
  temp_assignedWorkOrders: Map<string, iWorkOrder[]>;
  serviceDate: string;
  workOrders: iWorkOrder[];
  technicianId: string;
  ReplaceForCrewId?: string;
}): Map<string, iWorkOrder[]> => {
  // Create a copy of the list to avoid mutating the original list
  let updatedList = new Map(temp_assignedWorkOrders);

  // Extract the current list of items for the given technician
  const existingItems = updatedList.get(technicianId) || [];

  // Filter out the item being dragged

  const filteredItems = existingItems.filter(
    (t) => t.serviceDate !== serviceDate
  );

  // If the dragged item exists, update the list with the new order
  updatedList.set(technicianId, [...filteredItems, ...workOrders]);
  return updatedList;
};

export const unAssignWorkOrder = (
  workOrder: iWorkOrder,
  temp_assignedWorkOrders: Map<string, iWorkOrder[]>,
  temp_unassignedWorkOrders: iWorkOrder[],
  crewList: iCrew[],
  serviceDate: null | string,
  dispatch: Dispatch,
  filters: IWorkOrderFilters
): ProcessedWorkOrders => {
  if (workOrder?.crewId) {
    temp_unassignedWorkOrders = [
      {
        ...temp_assignedWorkOrders
          .get(
            crewList?.find((cl: any) => cl.crewId === workOrder?.crewId)
              ?.technician1EmployeeId ?? ""
          )
          ?.find((t: any) => t.id === workOrder.id),
        crewId: null,
        // technician1EmployeeId: null,
        // technician2EmployeeId: null,
        // technician3EmployeeId: null,
        // technician4EmployeeId: null,
        technician1Employee: null,
        technician2Employee: null,
        technician3Employee: null,
        technician4Employee: null,
        ...(serviceDate && {
          serviceDate: serviceDate,
        }),
      },
      ...temp_unassignedWorkOrders,
    ];
    dispatch(setUnassignedWorkOrders(temp_unassignedWorkOrders));
    dispatch(filterWorkorders(filters));

    temp_assignedWorkOrders =
      workOrder?.crewId && workOrder.id
        ? RemoveAssignedTaskInCrew(
            temp_assignedWorkOrders,
            workOrder.id,
            undefined,
            crewList,
            workOrder?.crewId
          )
        : temp_assignedWorkOrders;
    dispatch(
      setAssignedWorkOrders(Object.fromEntries(temp_assignedWorkOrders))
    );
    dispatch(filterWorkorders(filters));
  } else {
    // #Added this check
    if (workOrder?.technician1Employee?.id) {
      temp_unassignedWorkOrders = [
        {
          ...temp_assignedWorkOrders
            .get(workOrder?.technician1Employee?.id ?? "")
            ?.find((t: any) => t.id === workOrder.id),
          crewId: null,
          technician1Employee: null,
          technician2Employee: null,
          technician3Employee: null,
          technician4Employee: null,
          ...(serviceDate && {
            serviceDate: serviceDate,
          }),
        },
        ...temp_unassignedWorkOrders,
      ];
      dispatch(setUnassignedWorkOrders(temp_unassignedWorkOrders));
    }
    const technicianId = workOrder.technician1Employee?.id;
    technicianId;
    if (technicianId) {
      temp_assignedWorkOrders = RemoveAssignedTask(
        temp_assignedWorkOrders,
        technicianId,
        workOrder.id ?? ""
      );

      dispatch(
        setAssignedWorkOrders(Object.fromEntries(temp_assignedWorkOrders))
      );
      dispatch(filterWorkorders(filters));
    }
  }
  return {
    assignedTasks: temp_assignedWorkOrders,
    unassignedTasks: temp_unassignedWorkOrders,
  };
};
export const updateSequenceAndOtherDetailsOnAllWorkorders = (
  workOrderId: string,
  temp_unassignedWorkOrders: iWorkOrder[],
  sourceTechId: string,
  destTechId: string,
  temp_assignedWorkOrders: Map<string, iWorkOrder[]>,
  destinationIndex: number,
  sourceTechIndex: number,
  destinationDay: string,
  sourceDay: string,
  setHoldWorkOrderForUndoChanges: React.Dispatch<
    React.SetStateAction<iWorkOrder[]>
  >,
  dispatch: Dispatch,
  technicians: iTechnicians[],
  filters: IWorkOrderFilters,
  destinationCrew?: iCrew,
  sourceCrew?: iCrew,
  isDestinationCrew = false,
  isSourceCrew = false
): void => {
  const sourceWorkOrders =
    sourceTechId === CONSTANTS.UNASSIGN
      ? // ? [unassignedWorkOrders.find((t: any) => t.id === workOrderId) ?? {}]
        temp_unassignedWorkOrders?.sort((a: any, b: any) =>
          CompareDailySeqNum(a, b, CONSTANTS.UNASSIGN)
        ) ?? []
      : temp_assignedWorkOrders
          .get(sourceTechId)
          ?.filter((t: any) => t.serviceDate === sourceDay)
          ?.sort((a: any, b: any) => CompareDailySeqNum(a, b, sourceTechId)) ??
        [];

  //update below code to get only crew workorders if isDestinationCrew is true
  const destWorkOrders =
    (isDestinationCrew && isSourceCrew) ||
    (isDestinationCrew && sourceTechId === CONSTANTS.UNASSIGN)
      ? temp_assignedWorkOrders
          .get(destTechId)
          ?.filter(
            (t: any) =>
              t.crewId === destinationCrew?.crewId &&
              t.serviceDate === destinationDay
          )
          ?.sort((a: any, b: any) => CompareDailySeqNum(a, b, destTechId)) ?? []
      : temp_assignedWorkOrders
          .get(destTechId)
          ?.filter((t: any) => t.serviceDate === destinationDay)
          ?.sort((a: any, b: any) => CompareDailySeqNum(a, b, destTechId)) ??
        [];

  //Used when Task edit modal is being used
  // if (sourceTechIndex === -1) {
  sourceTechIndex = sourceWorkOrders.findIndex(
    (t: any) => t.id === workOrderId
  );
  // }
  //If Destination index is not provided then set it to the last index and if lenght is zero than make it 100
  if (destinationIndex === -1) {
    destinationIndex = destWorkOrders.length ? destWorkOrders.length : 0;
  }
  const [originalMovedWorkOrder] =
    sourceWorkOrders?.splice(sourceTechIndex, 1) ?? [];
  const movedWorkOrder = { ...originalMovedWorkOrder };

  if (
    sourceTechId === destTechId &&
    sourceDay === destinationDay &&
    sourceCrew === destinationCrew
  ) {
    // Reordering within the same column
    sourceWorkOrders?.splice(destinationIndex, 0, movedWorkOrder);

    // Calculate new sequence number
    sourceWorkOrders &&
      calculateNewSequences(
        sourceWorkOrders,
        destinationIndex,
        sourceTechId,
        setHoldWorkOrderForUndoChanges,
        isDestinationCrew
      );

    // Update the state with the reordered work orders

    temp_assignedWorkOrders = replaceAllWorkordersForaSpecificDate({
      temp_assignedWorkOrders: temp_assignedWorkOrders,
      serviceDate: destinationDay,
      workOrders: sourceWorkOrders,
      technicianId: sourceTechId,
    });

    //Copy sourceWorkorders to other assigned workorders if it is crew
    temp_assignedWorkOrders = updateAllWorkOrdersIfCrewMember(
      sourceWorkOrders,
      temp_assignedWorkOrders
    );
    // temp_assignedWorkOrders = newAssignedWorkOrders;
  } else {
    // let t_assigned = new Map(temp_assignedWorkOrders);

    //Remove the workorder from crew'techinians if it is moving to other crew
    if (isSourceCrew && movedWorkOrder.id) {
      temp_assignedWorkOrders = RemoveAssignedTaskInCrew(
        temp_assignedWorkOrders,
        movedWorkOrder.id,
        sourceCrew
      );
    }

    // Moving to another column
    // movedWorkOrder.technician1EmployeeId = ;
    populateEmployeeDetailsFromTechnician(
      destTechId,
      movedWorkOrder,
      technicians
    );
    movedWorkOrder.serviceDate = destinationDay;
    // movedWorkOrder.technician1Employee &&
    //   (movedWorkOrder.technician1Employee.id = destTechId);
    // Below alos needs if crew is moved to technician
    if (isDestinationCrew || (isSourceCrew && !isDestinationCrew)) {
      movedWorkOrder.crewId = destinationCrew?.crewId;
      destinationCrew?.technician2EmployeeId
        ? populateEmployeeDetailsFromTechnician(
            destinationCrew?.technician2EmployeeId,
            movedWorkOrder,
            technicians,
            "Employee2"
          )
        : (movedWorkOrder.technician2Employee = null);
      destinationCrew?.technician3EmployeeId
        ? populateEmployeeDetailsFromTechnician(
            destinationCrew?.technician3EmployeeId,
            movedWorkOrder,
            technicians,
            "Employee3"
          )
        : (movedWorkOrder.technician3Employee = null);
      destinationCrew?.technician4EmployeeId
        ? populateEmployeeDetailsFromTechnician(
            destinationCrew?.technician4EmployeeId,
            movedWorkOrder,
            technicians,
            "Employee4"
          )
        : (movedWorkOrder.technician4Employee = null);
    }

    destWorkOrders?.splice(destinationIndex, 0, movedWorkOrder);

    // Calculate new sequence number
    destWorkOrders &&
      calculateNewSequences(
        destWorkOrders,
        destinationIndex,
        destTechId,
        setHoldWorkOrderForUndoChanges,
        isDestinationCrew
      );

    // Update the state with the moved work orders

    temp_assignedWorkOrders = replaceAllWorkordersForaSpecificDate({
      temp_assignedWorkOrders: temp_assignedWorkOrders,
      serviceDate: destinationDay,
      workOrders: destWorkOrders,
      technicianId: destTechId,
    });

    if (isDestinationCrew && destinationCrew) {
      temp_assignedWorkOrders = moveWrokOrderToCrew(
        movedWorkOrder,
        temp_assignedWorkOrders,
        destinationCrew
      );
    }

    if (sourceTechId === CONSTANTS.UNASSIGN) {
      // temp_assignedWorkOrders = temp_assignedWorkOrders;
      // setUnassignedWorkOrders(
      temp_unassignedWorkOrders = temp_unassignedWorkOrders.filter(
        (t: any) => t.id !== workOrderId
      );
    } else if (!isSourceCrew) {
      temp_assignedWorkOrders = replaceAllWorkordersForaSpecificDate({
        temp_assignedWorkOrders: temp_assignedWorkOrders,
        serviceDate: sourceDay,
        workOrders: sourceWorkOrders,
        technicianId: sourceTechId,
      });
      // );
    } else if (isSourceCrew) {
      temp_assignedWorkOrders = temp_assignedWorkOrders;
    }

    // const newAssignedWorkOrders = new Map(assignedWorkOrders);
    // sourceWorkOrders &&
    //   newAssignedWorkOrders.set(sourceTechORCrewId, sourceWorkOrders);
    // destWorkOrders && newAssignedWorkOrders.set(destTechId, destWorkOrders);
  }

  dispatch(setAssignedWorkOrders(Object.fromEntries(temp_assignedWorkOrders)));
  dispatch(filterWorkorders(filters));
  dispatch(setUnassignedWorkOrders(temp_unassignedWorkOrders));
  dispatch(filterWorkorders(filters));
  // Set the flag to indicate that changes are being reviewed
  // setIsReviewingChanges(true);
};

const calculateNewSequences = (
  workOrders: iWorkOrder[],
  startIndex: number,
  techId: string,
  setHoldWorkOrderForUndoChanges: React.Dispatch<
    React.SetStateAction<iWorkOrder[]>
  >,
  isDestinationCrew = false
) => {
  if (workOrders.length === 0) {
    setTechSequence(workOrders[startIndex], techId, 100, isDestinationCrew); // Default sequence number for an empty column
  } else if (startIndex === 0) {
    setTechSequence(
      workOrders[startIndex],
      techId,
      workOrders[1] ? getTechSequence(workOrders[1], techId)! * 0.5 : 100,
      isDestinationCrew
    );
  } else if (startIndex === workOrders.length - 1) {
    setTechSequence(
      workOrders[startIndex],
      techId,
      getTechSequence(workOrders[startIndex - 1], techId)! + 100,
      isDestinationCrew
    );
  } else if (
    workOrders[startIndex + 1] &&
    getTechSequence(workOrders[startIndex - 1], techId) ===
      getTechSequence(workOrders[startIndex + 1], techId)
  ) {
    const startSequence = getTechSequence(workOrders[startIndex - 1], techId)!;
    let endIndex = startIndex + 1;

    // Find the first work order with a larger sequence number
    while (
      endIndex < workOrders.length &&
      getTechSequence(workOrders[endIndex], techId)! <= startSequence
    ) {
      endIndex++;
    }

    const endSequence =
      endIndex < workOrders.length
        ? getTechSequence(workOrders[endIndex], techId)!
        : startSequence + 100;

    const step = Math.round(
      (endSequence - startSequence) / (endIndex - startIndex + 1)
    );

    for (let i = startIndex; i < endIndex; i++) {
      const newSequence = startSequence + step * (i - startIndex + 1);
      handleHoldWorkOrderForUndoChanges(
        setHoldWorkOrderForUndoChanges,
        workOrders[i]
      );
      setTechSequence(workOrders[i], techId, newSequence, isDestinationCrew);
    }
  } else {
    setTechSequence(
      workOrders[startIndex],
      techId,
      Math.round(
        (getTechSequence(workOrders[startIndex - 1], techId)! +
          getTechSequence(workOrders[startIndex + 1], techId)!) /
          2
      ),
      isDestinationCrew
    );
  }
};

const getTechSequence = (
  workOrder: iWorkOrder,
  techId: string
): number | null => {
  if (!workOrder) return null;
  if (workOrder.technician1Employee?.id === techId)
    return workOrder.dailySequenceTech1 ?? 100;
  if (workOrder.technician2Employee?.id === techId)
    return workOrder.dailySequenceTech2 ?? 100;
  if (workOrder.technician3Employee?.id === techId)
    return workOrder.dailySequenceTech3 ?? 100;
  if (workOrder.technician4Employee?.id === techId)
    return workOrder.dailySequenceTech4 ?? 100;
  return null;
};

const setTechSequence = (
  workOrder: iWorkOrder,
  techId: string,
  newSequence: number,
  isDestinationCrew = false
) => {
  if (workOrder.technician1Employee?.id === techId)
    workOrder.dailySequenceTech1 = newSequence;
  if (workOrder.technician2Employee?.id === techId)
    workOrder.dailySequenceTech2 = newSequence;
  if (workOrder.technician3Employee?.id === techId)
    workOrder.dailySequenceTech3 = newSequence;
  if (workOrder.technician4Employee?.id === techId)
    workOrder.dailySequenceTech4 = newSequence;

  //iF this workroer is assigned to crew then we need to update the sequence for other technician apart from the technician 1.
  if (isDestinationCrew) {
    if (workOrder.technician2Employee?.id)
      workOrder.dailySequenceTech2 = newSequence;
    else workOrder.dailySequenceTech2 = null;
    if (workOrder.technician3Employee?.id)
      workOrder.dailySequenceTech3 = newSequence;
    else workOrder.dailySequenceTech3 = null;
    if (workOrder.technician4Employee?.id)
      workOrder.dailySequenceTech4 = newSequence;
    else workOrder.dailySequenceTech4 = null;
  }
  //if this workorder is
};

const moveWrokOrderToCrew = (
  workorder: iWorkOrder,
  assignedWorkOrders: Map<string, iWorkOrder[]>,
  crew: iCrew
) => {
  return GetAWOForAddNewWorkOrder(assignedWorkOrders, workorder);
};

export const giveWarningInCaseOfCrewAssignment = (
  destinationCrew: iCrew,
  isSameColumn: boolean,
  temp_assignedWorkOrders: Map<string, iWorkOrder[]>,
  setWarning: React.Dispatch<React.SetStateAction<iWarning>>
) => {
  // const assignedWorkOrders = useSelector<RootState, Map<string, iWorkOrder[]>>(
  //   (state: RootState) =>
  //     new Map(Object.entries(state.workorder.assignedWorkOrders))
  // );
  // #TODO this code should be part of a function helper
  let techNameSeqNotChange = "";
  const tech1EmployeeId = destinationCrew?.technician1EmployeeId;
  if (tech1EmployeeId) {
    const workOrdersForTech1 = temp_assignedWorkOrders.get(tech1EmployeeId);
    const isTech1HasOtherWorkOrder = isSameColumn
      ? workOrdersForTech1 &&
        workOrdersForTech1?.filter(
          (awo: iWorkOrder) => awo?.crewId != destinationCrew?.crewId
        )?.length > 0
      : false;
    if (isTech1HasOtherWorkOrder)
      techNameSeqNotChange += `${destinationCrew?.technician1EmployeeName}, `;
  }
  const tech2EmployeeId = destinationCrew?.technician2EmployeeId;
  if (tech2EmployeeId) {
    const workOrdersForTech2 = temp_assignedWorkOrders.get(tech2EmployeeId);
    const isTech2HasOtherWorkOrder = isSameColumn
      ? workOrdersForTech2 &&
        workOrdersForTech2?.filter((awo: any) => awo.crewId === null).length > 0
      : false;
    if (isTech2HasOtherWorkOrder)
      techNameSeqNotChange += `${destinationCrew?.technician2EmployeeName}, `;
  }
  const tech3EmployeeId = destinationCrew?.technician3EmployeeId;
  if (tech3EmployeeId) {
    const workOrdersForTech3 = temp_assignedWorkOrders.get(tech3EmployeeId);
    const isTech3HasOtherWorkOrder = isSameColumn
      ? workOrdersForTech3 &&
        workOrdersForTech3?.filter((awo: any) => awo.crewId === null).length > 0
      : false;

    if (isTech3HasOtherWorkOrder)
      techNameSeqNotChange += `${destinationCrew?.technician3EmployeeName}, `;
  }
  const tech4EmployeeId = destinationCrew?.technician4EmployeeId;
  if (tech4EmployeeId) {
    const workOrdersForTech4 = temp_assignedWorkOrders.get(tech4EmployeeId);
    const isTech4HasOtherWorkOrder = isSameColumn
      ? workOrdersForTech4 &&
        workOrdersForTech4?.filter((awo: any) => awo.crewId === null).length > 0
      : false;
    if (isTech4HasOtherWorkOrder)
      techNameSeqNotChange += `${destinationCrew?.technician4EmployeeName}, `;
  }
  // console.info('Line----660 index.jsx dailySequenceObj', dailySequenceObj);
  // const updatedObj = {
  //   workOrderId: draggableId,
  //   serviceDate: format(new Date(destinationDay), "yyyyMMdd"),
  //   ...dailySequenceObj, // Indexes are 0 based, so to get seq_num >0
  // };
  if (techNameSeqNotChange !== "") {
    setWarning({
      message: `Please review sequence on technician view as ${techNameSeqNotChange} has their individual workorder as well.`,
    });
    return;
  }
};

export const populateEmployeeDetailsFromTechnician = (
  technicianId: string,
  workorder: iWorkOrder,
  technicians: iTechnicians[],
  employeeKey: string = "Employee1"
) => {
  // Find the technician with the matching ID
  const technician = technicians.find((tech) => tech.id === technicianId);

  // If the technician is found, populate the specified employee field on the workorder
  if (technician && employeeKey === "Employee1") {
    workorder.technician1Employee = {
      // email: technician.email,
      firstName: technician.firstName,
      lastName: technician.lastName,
      id: technician.id,
      // mobile: technician.mobile,
      // phone: technician.phone,
    };
  } else if (technician && employeeKey === "Employee2") {
    workorder.technician2Employee = {
      // email: technician.email,
      firstName: technician.firstName,
      lastName: technician.lastName,
      id: technician.id,
      // mobile: technician.mobile,
      // phone: technician.phone,
    };
  } else if (technician && employeeKey === "Employee3") {
    workorder.technician3Employee = {
      // email: technician.email,
      firstName: technician.firstName,
      lastName: technician.lastName,
      id: technician.id,
      // mobile: technician.mobile,
      // phone: technician.phone,
    };
  } else if (technician && employeeKey === "Employee4") {
    workorder.technician4Employee = {
      // email: technician.email,
      firstName: technician.firstName,
      lastName: technician.lastName,
      id: technician.id,
      // mobile: technician.mobile,
      // phone: technician.phone,
    };
  } else {
    // Handle the case where the technician is not found
    console.error(`Technician with id ${technicianId} not found.`);
  }
};

// We assume interfaces iWorkOrder and iWorkOrderDeltaState are defined, and the holdWorkOrderForUndoChanges variable is available.

// This function compares the fields of two work orders and returns the fields with differences.
export const getDifferencesInWorkOrders = (
  heldWorkOrder: iWorkOrder,
  currentWorkOrder: iWorkOrder
): Partial<iWorkOrder> | null => {
  // List of fields to compare
  const fieldsToCompare = [
    "serviceDate",
    "estimatedServiceTimeMinutes",
    "dailySequenceTech1",
    "dailySequenceTech2",
    "dailySequenceTech3",
    "dailySequenceTech4",
    "crewId",
    "technician1Employee",
    "technician2Employee",
    "technician3Employee",
    "technician4Employee",
  ];

  // Check for any differences in the specified fields
  const isDifferent = fieldsToCompare.some((field) => {
    return (
      JSON.stringify(heldWorkOrder[field as keyof iWorkOrder]) !==
      JSON.stringify(currentWorkOrder[field as keyof iWorkOrder])
    );
  });

  // If differences are found, construct an object with the specified fields
  if (isDifferent) {
    const selectedFields = {
      workOrderId: currentWorkOrder.id,
      serviceDate: currentWorkOrder.serviceDate,
      estimatedServiceTimeMinutes: currentWorkOrder.estimatedServiceTimeMinutes,
      dailySequenceTech1: currentWorkOrder.dailySequenceTech1,
      dailySequenceTech2: currentWorkOrder.dailySequenceTech2,
      dailySequenceTech3: currentWorkOrder.dailySequenceTech3,
      dailySequenceTech4: currentWorkOrder.dailySequenceTech4,
      crewId: currentWorkOrder.crewId,
      technician1EmployeeId: currentWorkOrder.technician1Employee?.id ?? null, // Assuming technician1Employee is an object with an id property
      technician2EmployeeId: currentWorkOrder.technician2Employee?.id ?? null,
      technician3EmployeeId: currentWorkOrder.technician3Employee?.id ?? null,
      technician4EmployeeId: currentWorkOrder.technician4Employee?.id ?? null,
    };
    return selectedFields;
  }

  // If no differences, return null
  return null;
};

//If any of source workorder is part of crew then need to update other workorders in assignedWrorkOrders
export const updateAllWorkOrdersIfCrewMember = (
  sourceWorkOrders: iWorkOrder[],
  temp_assignedWorkOrders: Map<string, iWorkOrder[]>
) => {
  sourceWorkOrders.forEach((workOrder) => {
    if (workOrder.crewId) {
      if (workOrder.technician1Employee?.id) {
        temp_assignedWorkOrders = ReplaceOrAddWorkOrder(
          temp_assignedWorkOrders,
          workOrder,
          workOrder.technician1Employee?.id
        );

        if (workOrder.technician2Employee?.id) {
          temp_assignedWorkOrders = ReplaceOrAddWorkOrder(
            temp_assignedWorkOrders,
            workOrder,
            workOrder.technician2Employee?.id
          );
        }

        if (workOrder.technician3Employee?.id) {
          temp_assignedWorkOrders = ReplaceOrAddWorkOrder(
            temp_assignedWorkOrders,
            workOrder,
            workOrder.technician3Employee?.id
          );
        }

        if (workOrder.technician4Employee?.id) {
          temp_assignedWorkOrders = ReplaceOrAddWorkOrder(
            temp_assignedWorkOrders,
            workOrder,
            workOrder.technician4Employee?.id
          );
        }
      }
    }
  });
  return temp_assignedWorkOrders;
};

export const checkTechnicianSelection = (
  technicians: any,
  otherRoleCheckList: string[],
  setOtherRoleCheckList: React.Dispatch<React.SetStateAction<string[]>>,
  setFilters: React.Dispatch<React.SetStateAction<any>>,
  timeperiodFilter: iFilters,
  setIsAllTechSelectedWhichHasWorkOrder: React.Dispatch<
    React.SetStateAction<boolean>
  >,
  temp_assignedWorkOrders: Map<string, iWorkOrder[]>
) => {
  let allCheckedTechnicians: any = [];
  const idsForTechniciansRole = technicians
    .filter((tech: any) => tech.role.includes(CONSTANTS.TECHNICIAN))
    .map((t: any) => t.id);
  const localRoleCheckList =
    JSON.parse(localStorage.getItem("otherRoleCheckList") as string) || [];
  const localCheckList =
    JSON.parse(localStorage.getItem("checkList") as string) || [];

  if (otherRoleCheckList.length === 0 && localRoleCheckList === null) {
    // setOtherRoleCheckList(otherTechsIds);
    allCheckedTechnicians = idsForTechniciansRole;
    setFilters([timeperiodFilter, allCheckedTechnicians]);
  } else {
    if (localRoleCheckList) setOtherRoleCheckList([...localRoleCheckList]);
    const newCheckList =
      localCheckList === null ? idsForTechniciansRole : localCheckList;
    const newRoleCheckList =
      localRoleCheckList === null ? [] : localRoleCheckList;
    allCheckedTechnicians = [
      ...new Set([...newCheckList, ...newRoleCheckList]),
    ];
    setFilters([timeperiodFilter, allCheckedTechnicians]);
  }
  setIsAllTechSelectedWhichHasWorkOrder(
    Array.from(temp_assignedWorkOrders.keys()).every((at: string) =>
      allCheckedTechnicians.includes(at)
    )
  );
};

export const getMassUpdateProps = (
  columnMenuAchor: any,
  setMassUpdateProps: React.Dispatch<React.SetStateAction<IMassUpdateProps>>,
  setModal: React.Dispatch<React.SetStateAction<string>>
) => {
  if (columnMenuAchor) {
    setMassUpdateProps({
      isMassUpdate: true,
      technicianId:
        columnMenuAchor?.id.split("-")[1] === "technician"
          ? columnMenuAchor?.id.split("-technician-")[1]?.split("-day-")[0]
          : "",
      crewId:
        columnMenuAchor?.id.split("-")[1] === "crew"
          ? columnMenuAchor?.id.split("-crew-")[1]?.split("-day-")[0]
          : "",
      serviceDate: columnMenuAchor?.id.split("-day-")[1],
    });
    setModal(CONSTANTS.MASS_UPDATE_WO);
  }
};

export const createMapFromWorkOrderList = (
  workOrders: iWorkOrder[]
): ProcessedWorkOrders => {
  const assignedTasks: Map<string, iWorkOrder[]> = new Map();
  const unassignedTasks: iWorkOrder[] = [];

  workOrders = workOrders.filter((wo) => wo.status !== "ON HOLD");

  workOrders &&
    workOrders.forEach((task) => {
      if (!task) return;
      // if Task daily sequence is null then assign daily sequence as 100
      if (task.dailySequenceTech1 === null) task.dailySequenceTech1 = 100;
      if (task.technician1Employee?.id === undefined && task.partner === null) {
        unassignedTasks.push(task);
      } else {
        if (
          task.technician1Employee?.id !== undefined &&
          task.partner === null
        ) {
          if (!assignedTasks.get(task.technician1Employee.id)) {
            assignedTasks.set(task.technician1Employee.id, []);
          }
          if (
            assignedTasks
              .get(task.technician1Employee.id)
              ?.filter((asignTask) => asignTask.id === task.id).length === 0
          ) {
            assignedTasks.get(task.technician1Employee.id)?.push(task);
          }
        }

        if (
          task.technician2Employee?.id !== undefined &&
          task.partner === null
        ) {
          if (!assignedTasks.get(task.technician2Employee.id)) {
            assignedTasks.set(task.technician2Employee.id, []);
          }
          if (
            assignedTasks
              .get(task.technician2Employee.id)
              ?.filter((asignTask) => asignTask.id === task.id).length === 0
          ) {
            assignedTasks.get(task.technician2Employee.id)?.push(task);
          }
        }

        if (
          task.technician3Employee?.id !== undefined &&
          task.partner === null
        ) {
          if (!assignedTasks.get(task.technician3Employee.id)) {
            assignedTasks.set(task.technician3Employee.id, []);
          }
          if (
            assignedTasks
              .get(task.technician3Employee.id)
              ?.filter((asignTask) => asignTask.id === task.id).length === 0
          ) {
            assignedTasks.get(task.technician3Employee.id)?.push(task);
          }
        }

        if (
          task.technician4Employee?.id !== undefined &&
          task.partner === null
        ) {
          if (!assignedTasks.get(task.technician4Employee.id)) {
            assignedTasks.set(task.technician4Employee.id, []);
          }
          if (
            assignedTasks
              .get(task.technician4Employee.id)
              ?.filter((asignTask) => asignTask.id === task.id).length === 0
          ) {
            assignedTasks.get(task.technician4Employee.id)?.push(task);
          }
        }
      }
    });

  return { assignedTasks, unassignedTasks };
};

export const filterWorkOrdersHelper = (
  {
    searchString,
    isHideCompleted = false,
    isHideUnassigned = false,
    filteredServiceTypes = [],
  }: IWorkOrderFilters,
  assignedWorkOrders: Map<string, iWorkOrder[]>,
  unassignedWorkOrders: iWorkOrder[]
) => {
  let filteredAssignedWorkOrders = new Map<string, iWorkOrder[]>(new Map());
  let filteredUnassignedWorkOrders: iWorkOrder[] = unassignedWorkOrders;

  if (searchString && searchString.trim() !== "") {
    Array.from(assignedWorkOrders.keys()).forEach((key) => {
      assignedWorkOrders.get(key)?.forEach((element: any) => {
        if (
          JSON.stringify(element)
            .toLowerCase()
            .includes(searchString.toLowerCase())
        ) {
          filteredAssignedWorkOrders.set(
            key,
            filteredAssignedWorkOrders.get(key)?.length
              ? [...(filteredAssignedWorkOrders.get(key) ?? []), element]
              : [element]
          );
        }
      });
    });

    unassignedWorkOrders.forEach((element) => {
      if (
        JSON.stringify(element)
          .toLowerCase()
          .includes(searchString.toLowerCase())
      ) {
        filteredUnassignedWorkOrders = filteredUnassignedWorkOrders?.length
          ? [...filteredUnassignedWorkOrders, element]
          : [element];
      }
    });
  } else {
    filteredAssignedWorkOrders = new Map(assignedWorkOrders);
  }

  if (isHideCompleted) {
    filteredAssignedWorkOrders.forEach((value, key) => {
      filteredAssignedWorkOrders.set(
        key,
        value?.filter((t: iWorkOrder) => t.status !== CONSTANTS.COMPLETED)
      );
    });
  }

  if (isHideUnassigned) {
    filteredUnassignedWorkOrders = [];
  }

  if (filteredServiceTypes.length > 0) {
    filteredAssignedWorkOrders.forEach((value, key) => {
      filteredAssignedWorkOrders.set(
        key,
        value?.filter((t: iWorkOrder) =>
          filteredServiceTypes.includes(t.jobServiceType ?? "Not Specified")
        )
      );
    });

    filteredUnassignedWorkOrders = filteredUnassignedWorkOrders.filter(
      (t: iWorkOrder) =>
        filteredServiceTypes.includes(t.jobServiceType ?? "Not Specified")
    );
  }

  return { filteredAssignedWorkOrders, filteredUnassignedWorkOrders };
};

// Function to get map of service type and number of workorders for each service type
export const getServiceTypeCount = (
  workOrders: iWorkOrder[],
  existingServiceTypes: string[]
): Map<string, number> => {
  const serviceTypeCount = new Map<string, number>();

  workOrders.forEach((workOrder) => {
    if (serviceTypeCount.has(workOrder.jobServiceType ?? "Not Specified")) {
      serviceTypeCount.set(
        workOrder.jobServiceType ?? "Not Specified",
        (serviceTypeCount.get(workOrder.jobServiceType ?? "Not Specified") ??
          0) + 1
      );
    } else {
      serviceTypeCount.set(workOrder.jobServiceType ?? "Not Specified", 1);
    }
  });

  //if Existing service types are not present in the workorders then add them with 0 count
  //This is requied when someone has changed date filter and had selected some service types in filters
  existingServiceTypes.forEach((serviceType) => {
    if (!serviceTypeCount.has(serviceType)) {
      serviceTypeCount.set(serviceType, 0);
    }
  });

  return serviceTypeCount;
};
